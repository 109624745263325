/* You can add global styles to this file, and also import other style files */
@import "src/app/shared/variables";

html, body {
  height: 100%;
}

.ant-tooltip {
  .math-tooltip-header {
    font-weight: 500;
  }

  .ant-tooltip-inner {
    background-color: #FFF8E1;
    color: #000;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #babac0;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: #f6f4f0;
  border-radius: 4px;
}

.mat-mdc-dialog-container {
  height: 100% !important;
  margin: 30px !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #ffffff;
  border: 1px solid #00e85e;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #62b900;
  border: 1px solid #008a37;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #ffffff;
  border: 1px solid #00e85e;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #62b900;
  border: 1px solid #008a37;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:is(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #ffffff;
  border: 1px solid #00e85e;
}
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:is(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #62b900;
  border: 1px solid #008a37;
}

.red-snackbar {
  background: #d14b41;
  color: white;
}
.red-snackbar button {
  background-color: #d14b41;
  color: white !important;
  border: none;
}

.green-snackbar {
  background: rgb(24, 114, 55);
  color: white;
}
.green-snackbar button {
  background-color: rgb(24, 114, 55);
  color: white;
  border: none;
}

.ant-layout {
  //background-color: $pifagor-gray-background!important;
}

div.checkbox{
  position: relative;
  padding: 0;
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  div.border{
    left: 0;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    z-index: 1;
    cursor: pointer;
    &:hover{
      border: 1px solid $pifagor-blue-background;
    }
    &.disabled{
      display: none;
    }
  }

  input[type=checkbox]{
    height: 18px;
    width: 18px;
    &:not(:disabled){
      cursor: pointer;
    }
    &:disabled{
      cursor: not-allowed;
    }
  }
}

div.radio{
  position: relative;
  padding: 0;
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  div.border{
    left: 0;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    &:hover{
      border: 1px solid $pifagor-blue-background;
    }
    &.disabled{
      display: none;
    }
  }

  input[type=radio]{
    &:not(:disabled){
      cursor: pointer;
    }
    &:disabled{
      cursor: not-allowed;
    }
  }
}

.cdk-global-scrollblock {
  overflow-y: hidden !important;
}

button {
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #EDE7F6;
}

.ant-table {
  th {
    background-color: #f5f5f5 !important;
    font-size: 12px;
    color: #666;

    &::before {
      display: none;
    }

    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: #613CB0;
    }
  }

  td,
  th {
    a.td-link {
      color: #613CB0;
    }

    .ant-tag {
      font-size: 10px;
    }
  }

  tr:not(.ant-table-expanded-row) {
    &:hover > td {
      background-color: #E9F6FE;
    }
  }

  th.sortable {
    .ant-table-column-title {
      position: static;
    }

    .ant-table-column-sorter-up {
      background-image: url('./assets/icons/sort-arrow-up.svg');
      background-repeat: no-repeat;
      position: relative;
      top: 2px;
      right: 10px;

      &.active {
        background-image: url('./assets/icons/sort-arrow-up-active.svg');
      }

      svg {
        visibility: hidden;
      }
    }

    .ant-table-column-sorter-down {
      background-image: url('./assets/icons/sort-arrow-down.svg');
      background-repeat: no-repeat;
      position: relative;
      right: 3px;
      bottom: 2px;

      &.active {
        background-image: url('./assets/icons/sort-arrow-down-active.svg');
      }

      svg {
        visibility: hidden;
      }
    }
  }

  tr > td > span {
    font-size: 12px;
    padding: 4px 0;

    &:first-child {
      padding-left: 4px;
    }

    &:last-child {
      padding-right: 4px;
    }
  }

  nz-pagination {
    .ant-pagination-item-active {
      border-color: #613CB0;
    }

    .ant-pagination-item-active a {
      color: #613CB0;
    }
  }

  nz-spin {
    position: sticky;
    top: 0;
  }

  @media (max-width: 576px) {
    .no-show-mobile {
      display: none;
    }

    th,
    td {
      width: 100%;
    }
  }
}

.no-content.ng-star-inserted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
